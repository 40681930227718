<template>
  <v-app overflow-hidden justify="center">
    <v-app-bar color="white" elevate-on-scroll scroll-target="#main-body" app>
      <v-responsive class="mx-auto" max-width="1080px" style="position: relative; top: 0.8vh;">
        <v-container class="fill-height">
          <v-tabs color="#363858">
            <v-avatar height="48" width="56" class="mr-md-10 mr-sm-5">
              <img src="logo.png"/>
            </v-avatar>
            <v-tab @click="$vuetify.goTo('#main-body')" class="text-md-h6 text-sm-h4 font-weight-black" style="color:#363858">编程云</v-tab>
            <v-tab @click="$vuetify.goTo('#aboutus')" class="text-md-h6 text-sm-h4 font-weight-black" style="color:#363858">关于我们</v-tab>
            <v-btn rounded height="40" class="ml-auto px-8" href="http://test.new.codeclassroom.org/register/" target="_blank" style="color:#363858;background-color:#E8F1FF;"><strong>注册</strong></v-btn>
            <v-btn rounded height="40" class="px-8" href="http://test.new.codeclassroom.org/login/?ref=/" target="_blank" style="color:#363858;background-color:#E8F1FF;"><strong>登录</strong></v-btn>
          </v-tabs>
        </v-container>
      </v-responsive>
    </v-app-bar>
    <v-main id="main-body" class="pb-10">
      <v-responsive class="mx-auto" max-width="1080px">
        <v-container justify="center">
          <v-row align="center" style="height: 80vh;">
            <v-col md="6" sm="12" class="d-none d-md-flex">
              <div style="position: absolute; top: 40vh; left: 30%; width: 200px; height:600px;">
                <v-img src="rocket.png"/>
              </div>
              <v-img :src="step+1 + '.png'" style="max-width: 90%;"/>
            </v-col>
            <v-col cols="12" md="6">
              <p class="text-h4" style="color:#363858"><strong>学习真正的编程语言</strong></p>
              <p class="text-subtitle-1 text--secondary">随着时代的发展逐步学习真正的编程语言和数学知识结合并挂钩微软MTA认证，最终踏入AI人工智能领域。</p>
              <br/>
              <br/>
              <br/>
              <v-row justify="center">
                <v-col cols="10" md="10">
                  <v-hover v-slot="{ hover }">
                    <v-card @click="step=0" rounded="lg" :outlined="(xl() || lg() || md() ) && (hover || step == 0)" elevation="0">
                      <div align="start" class="pa-5">
                        <v-btn rounded small style="width: 100px;height:35px"
                               :class="{'success': step == 0, 'blue': step != 0, 'blue--text': step != 0, 'lighten-5': step != 0}">
                         <strong>HTML&CSS</strong>
                        </v-btn>
                        <span class="ml-5 text-md-h6 text-sm-h5" style="color:#363858">网页制作</span>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" md="10" >
                  <v-hover v-slot="{ hover } ">
                    <v-card @click="step=1" rounded="lg" :outlined="(xl() || lg() || md() ) && (hover || step == 1)" elevation="0">
                      <div align="start" class="pa-5">
                        <v-btn rounded small style="width:100px;"
                               :class="{'success': step == 1, 'blue': step != 1, 'blue--text': step != 1, 'lighten-5': step != 1}">
                        <strong>JavaScript</strong>
                        </v-btn>
                        <span class="ml-5 text-md-h6 text-sm-h5" style="color:#363858">制作交互页面</span>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" md="10" >
                  <v-hover v-slot="{ hover }">
                    <v-card @click="step=2" rounded="lg" :outlined="(xl() || lg() || md() ) && (hover || step == 2)" elevation="0">
                      <div align="start" class="pa-5">
                        <v-btn rounded small style="width:100px;"
                               :class="{'success': step == 2, 'blue': step != 2, 'blue--text': step != 2, 'lighten-5': step != 2}">
                        <strong>Python</strong>
                        </v-btn>
                        <span class="ml-5 text-md-h6 text-sm-h5" style="color:#363858">大数据处理</span>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" md="10" >
                  <v-hover v-slot="{ hover }">
                    <v-card @click="step=3" rounded="lg" :outlined="(xl() || lg() || md() ) && (hover || step == 3)" elevation="0">
                      <div align="start" class="pa-5">
                        <v-btn rounded small style="width:100px;"
                               :class="{'success': step == 3, 'blue': step != 3, 'blue--text': step != 3, 'lighten-5': step != 3}">
                        <strong>图形化编程</strong>
                        </v-btn>
                        <span class="ml-5 text-md-h6 text-sm-h5" style="color:#363858">建立编程思维</span>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="my-15">
            <v-col>
              <v-card class="lighten-5" rounded="xl" style="background-color:#E8F1FF">
                <v-row align="center" justify="center" class="py-10">
                  <img src="qizhi.png" style="width: 370px;"/>
                  <h2 style="width: 180px; color:#363858" class="my-10"><strong>用任何你喜欢的方式学习编程</strong></h2>
                  <div>
                    <v-tooltip bottom :disabled="md() || lg() || xl()">
                      <template v-slot:activator="{ on, attrs} ">
                        <v-btn rounded class="darken-4 white--text mx-2" v-bind="attrs" v-on="on" style="background-color:#4C4E88">
                          <v-icon v-if="md() || lg() || xl()" left color="blue">mdi-play-circle</v-icon>
                          <v-icon v-else color="#4D508B">mdi-play-circle</v-icon>
                          <span class="d-none d-md-flex" style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">AI视频<br/>录播课程</span>
                        </v-btn>
                      </template>
                      <span style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">AI视频<br/>录播课程</span>
                    </v-tooltip>
                    <v-tooltip bottom :disabled="md() || lg() || xl()">
                      <template v-slot:activator="{ on, attrs} ">
                        <v-btn rounded class="darken-4 white--text mx-2" v-bind="attrs" v-on="on" style="background-color:#4C4E88">
                          <v-icon v-if="md() || lg() || xl()" left color="green">mdi-monitor</v-icon>
                          <v-icon v-else color="green">mdi-monitor</v-icon>
                          <span class="d-none d-md-flex" style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">线上<br/>直播课程</span>
                        </v-btn>
                      </template>
                      <span style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">线上<br/>直播课程</span>
                    </v-tooltip>
                    <v-tooltip bottom :disabled="md() || lg() || xl()">
                      <template v-slot:activator="{ on, attrs} ">
                        <v-btn rounded class="darken-4 white--text mx-2" v-bind="attrs" v-on="on" style="background-color:#4C4E88">
                          <v-icon v-if="md() || lg() || xl()" left color="purple">mdi-book-open-variant</v-icon>
                          <v-icon v-else color="purple">mdi-book-open-variant</v-icon>
                          <span class="d-none d-md-flex" style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">线下<br/>体验课程</span>
                        </v-btn>
                      </template>
                      <span style="width: 60px; font-size:0.75rem; white-space: pre-wrap;">线下<br/>体验课程</span>
                    </v-tooltip>
                  </div>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="my-15 py-10">
            <v-col cols="12" md="4">
              <v-card elevation="0" rounded="xl" class="lighten-5 pt-10 px-4 " style="background-color:#E8F1FF">
                <v-img class="mx-auto rounded-xl" src="python.png" max-width="90%"/>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card elevation="0" rounded="xl" class="lighten-5 pt-10 px-4" style="background-color:#E8F1FF">
                <v-img class="mx-auto rounded-xl" src="HTML.png" max-width="90%"/>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card elevation="0"  rounded="xl" class="lighten-5 pt-10 px-4 " style="background-color:#E8F1FF">
                <v-img class="mx-auto rounded-xl" src="JavaScript.png" max-width="90%"/>
              </v-card>
            </v-col>
          </v-row>
          <v-row id="aboutus" class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="left">
              <p class="text-h3" style="color:#363858"><strong>关于我们</strong></p>
              <p class="mt-12" style="color:#8B8C99">
              <strong>
                在编程云，我们相信科技可以为每一个人打开一扇充满希望和未来的门。这是为什么我们一直致力于让编程结合金融或其他应用场景应用，让它变得更加简洁易懂，让零基础的学者都可以轻松上手爱上编程。
              </strong>
              </p>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10 pa-0" :order="right">
              <v-img style="width: 100%;" class="rounded-xl" src="yanjiang.JPG"/>
            </v-col>
          </v-row>
          <v-row class="my-12">
            <v-tabs grow color="orange">
              <v-tab @click="$vuetify.goTo('#our-story')">
                <v-icon left>mdi-emoticon-lol-outline</v-icon>
                <span class="d-none d-sm-flex">我们的</span>故事
              </v-tab>
              <v-tab @click="$vuetify.goTo('#our-teams')">
                <v-icon left>mdi-account-group</v-icon>
                <span class="d-none d-sm-flex">我们的</span>团队
              </v-tab>
              <v-tab @click="$vuetify.goTo('#our-values')">
                <v-icon left>mdi-cards-heart</v-icon>
                <span class="d-none d-sm-flex">我们的</span>价值观
              </v-tab>
            </v-tabs>
          </v-row>
          <v-row id="our-story" class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="left">
              <p class="text-h4 mb-7 ml-4" style="color:#363858"><strong>我们的故事</strong></p>
              <v-img class="rounded-xl" src="visitors.jpeg"/>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10" :order="right">
              <p class="text-body-1" style="color:#8B8C99">
                由于教育领域现行的制度，枯燥的IT技术只是被一小部分人掌握，这就让那些拥有很强的业务能力的人们望而却步，编程云决定做一些改变，争取能培养更多的跨领域的技术人才。
              </p>
              <p class="text-body-1" style="color:#8B8C99">
                近年来随着大数据，人工智能，机器学习，区块链等技术在金融科技中的作用越来越大，编程云团队技术人员与金融背景的人才开始研发并逐步上线针对金融领域结合的编程课程内容：这里面涉及最前沿的人工智能，大数据分析与数据可视化，自动办公以及web3.0等前沿金融场景中的科技应用系列课程。
              </p>
            </v-col>
          </v-row>
          <v-row class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="right">
              <p class="text-body-1" style="color:#8B8C99">
                2018年第二届国际早教峰会上，编程云图形化小小数据分析师课程获得了最受家长信赖品牌荣誉证书。
              </p>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10" :order="left">
              <v-hover v-slot="{ hover }">
                <v-img class="rounded-xl" src="1-3.JPG">
                  <v-expand-transition>
                    <v-img v-if="hover" class="rounded-xl d-flex transition-fast-in-fast-out v-card--reveal"
                           src="2-3.JPG"/>
                  </v-expand-transition>
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
          <v-row class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="left">
              <v-hover v-slot="{ hover }">
                <v-img class="rounded-xl" src="2-4.JPG">
                  <v-expand-transition>
                    <v-img v-if="hover" class="rounded-xl d-flex transition-fast-in-fast-out v-card--reveal"
                           src="2-1.JPG"/>
                  </v-expand-transition>
                </v-img>
              </v-hover>
     <!--              <v-carousel
                  cycle
                  :show-arrows="false"
                  height="330"
                  interval="3000"
                  hide-delimiters>
                <v-carousel-item
                    v-for="(slide, i) in wangyi"
                    :key="i">
                  <v-img class="rounded-xl" :src="slide" />
                </v-carousel-item>
              </v-carousel>-->
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10" :order="right">
              <p class="text-body-1" style="color:#8B8C99">
                教育部张天保部长在网易举办的《致敬时代·赋能未来》峰会上进行了关于教育与民族发展的观点分享，编程云课堂获得“2018年度综合实力少儿编程教育品牌“ 。编程云创始人李彦博接受了网易采访并与张部长合影。
              </p>
            </v-col>
          </v-row>
          <v-row class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="right">
              <p class="text-body-1" style="color:#8B8C99">
                编程云创始人、金融四十人论坛新金融联盟数字金融部总监李彦博在金融四十人论坛北京总部给新金融联盟秘书长吴雨珊等20多名领导和同事线上线下同步分享Python与数据分析在金融场景中的应用案例。
              </p>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10" :order="left">
              <v-img class="rounded-xl" src="3-2.JPG"/>
            </v-col>
          </v-row>
          <v-row class="my-15" align="center">
            <v-col cols="12" md="6" class="pr-md-10" :order="left">
              <v-hover v-slot="{ hover }">
                <v-img class="rounded-xl" src="4-2.JPG">
                  <v-expand-transition>
                    <v-img v-if="hover" class="rounded-xl d-flex transition-fast-in-fast-out v-card--reveal"
                           src="4-1.JPG"/>
                  </v-expand-transition>
                </v-img>
              </v-hover>
            </v-col>
            <v-col cols="12" md="6" class="pl-md-10" :order="right">
              <p class="text-body-1" style="color:#8B8C99">
                编程云创始人李彦博与人人智慧董事长李晖达成战略合作协议，将为金融领域的银行，基金，保险，证券提供Python与数据分析在金融场景中的应用课程。
              </p>
            </v-col>
          </v-row>
          <v-row id="our-teams" class="mt-15 pt-15">
            <p class="text-h4 mx-auto" style="margin-bottom: 30px;color:#363858" ><strong>我们的团队</strong></p>
          </v-row>
          <v-row class="mb-15 pb-15">
            <v-col v-for="user in teams" cols="4" md="2" :key="user.name">
              <v-card elevation="0">
                <v-img class="rounded-lg" max-width="150" max-height="150" :aspect-ratio="1/1" :src="user.img"/>
                <v-card-title>{{ user.name }}</v-card-title>
                <v-card-subtitle>{{ user.title }}</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row id="our-values" class="my-12">
            <v-col cols="12" md="6" sm="12" style="padding-right: 30px;" :order="left">
              <p class="text-h4" style="margin-bottom: 30px;color:#363858"><strong>我们的价值观</strong></p>
              <p class="text-body-1" style="color:#8B8C99">
                大数据时代已经到来，我们团队也积极践行点对点线上协同合作，致力于通过智能合约技术让每个学员都可以在温馨的体验中学好编程技术，并将自己领域业务的理解融合起来做出更多垂直领域的项目，一起为更多学员赋能。
              </p>
            </v-col>
            <v-col cols="6" md="3" sm="6"  align-self="start" style="pading-left: 30px;" :order="right">
              <p>
                <v-btn text>
                  <v-icon color="red">mdi-cards-heart</v-icon>
                  温暖
                </v-btn>
              </p>
              <p>
                <v-btn text>
                  <v-icon color="amber accent-3">mdi-pen</v-icon>
                  专业
                </v-btn>
              </p>
              <p>
                <v-btn text>
                  <v-icon color="amber accent-3">mdi-handshake</v-icon>
                  真诚
                </v-btn>
              </p>
              <p>
                <v-btn text>
                  <v-icon color="green">mdi-lightbulb-variant</v-icon>
                  简单清晰
                </v-btn>
              </p>
            </v-col>
            <v-col cols="6" md="3" sm="6"  align-self="start" :order="right">
              <p>
                <v-btn text>
                  <v-icon color="red">mdi-fire</v-icon>
                  热情
                </v-btn>
              </p>
              <p>
                <v-btn text>
                  <v-icon color="amber accent-3">mdi-emoticon</v-icon>
                  有趣
                </v-btn>
              </p>
              <p>
                <v-btn text>
                  <v-icon color="green">mdi-pine-tree</v-icon>
                  个人成长
                </v-btn>
              </p>
            </v-col>
          </v-row>
<!--          <v-row class="my-12" align="center">
            <v-col md="6" class="pr-md-10" :order="right">
              <v-img class="rounded-xl" src="MTA.jpg"/>
            </v-col>
            <v-col md="6" class="pl-md-10" :order="left">
              <p class="text-h4">坚持对真正的编程语言和认证的追求</p>
              <p class="text-body-1">
                市面上充斥着很多非真正的编程语言的青少儿编程培训及证书认证。团队坚持只教真正的编程语言并配合微软MTA技能认证，给选择编程云的孩子们 片干净，快乐，专业的土壤去绿色的茁壮成长。
              </p>
            </v-col>
          </v-row>
          <v-row class="my-12" align="center">
            <v-col md="6" class="pr-md-10" :order="left">
              <p class="text-h4">我们永远把给学生创造最大价值放到首位</p>
              <p class="text-body-1">
                我们把学员体验放在第一位，深知初学者们的苦恼，致力于在温馨的学习环境中让他们达到目标
              </p>
            </v-col>
            <v-col md="6" class="pl-md-10" :order="right">
              <v-img class="rounded-xl" src="ux.png"/>
            </v-col>
          </v-row>
          <v-row class="my-12" align="center">
            <v-col md="6" class="pr-md-10" :order="right">
              <v-img class="rounded-xl" src="17.jpg"/>
            </v-col>
            <v-col md="6" class="pl-md-10" :order="left">
              <p class="text-h4">坚毅</p>
              <p class="text-body-1">
                为了达到我们的目标，我们敢于冒险并快速推进项目升级。
              </p>
            </v-col>
          </v-row>
          <v-row class="my-12" align="center">
            <v-col md="6" cols="12" class="pr-md-10" :order="left">
              <p class="text-h4">高效沟通</p>
              <p class="text-body-1">
                团队提倡直接沟通，拥抱反馈。我们始终认为沟通和反馈是提高团队效率最重要的要素，互相帮助解决问题深深流淌在我们的血液中。
              </p>
            </v-col>
            <v-col md="6" cols="12" class="pl-md-10" :order="right">
              <v-img class="rounded-xl" src="300.jpg"/>
            </v-col>
          </v-row>-->
        </v-container>
      </v-responsive>
    </v-main>
    <v-footer class="indigo darken-3 white--text pt-12"
              style="background-color: #3E416D !important;">
      <v-responsive class="mx-auto" max-width="1080px">
        <v-container justify="center">
          <v-row class="font-weight-regular">
            <v-col v-if="xl() || lg() || md()" md="2">
              <v-avatar height="48" width="56">
                <img src="logo.png"/>
              </v-avatar>
            </v-col>
            <v-col md="2" cols="6" class="mb-2 grey-text">
              <p class="text-body-1 mb-2">产品</p>
              <p class="text-caption md-1">公益编程1小时</p>
              <p class="text-caption md-1">线上AI课</p>
              <p class="text-caption md-1">线上直播课</p>
              <p class="text-caption md-1">线下合作店</p>
            </v-col>
            <v-col v-if="xl() || lg() || md()" md="2" cols="6">
              <p class="text-body-1 mb-2">公司</p>
              <p class="text-caption md-1">关于我们</p>
              <p class="text-caption md-1">编程1小时</p>
              <p class="text-caption md-1">机构课程引入</p>
              <p class="text-caption md-1">个人求职</p>
            </v-col>
            <v-col v-if="xl() || lg() || md()" md="2" cols="6">
              <p class="text-body-1 mb-2">编程语言</p>
              <p class="text-caption md-1">图形化编程</p>
              <p class="text-caption md-1">HTML&CSS</p>
              <p class="text-caption md-1">JavaScript</p>
              <p class="text-caption md-1">Python</p>
            </v-col>
            <v-col md="2" cols="6">
              <p class="text-body-1 mb-2">关注我们</p>
              <v-tooltip color="success" open-delay="200" right :disabled="sm() || xs()">
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-caption md-1"><span v-on="on" v-bind="attrs">微信<v-icon
                      color="white">mdi-wechat</v-icon></span></p>
                </template>
                <v-img src="weixin.png" dark max-width="200"/>
              </v-tooltip>
              <v-tooltip color="black" open-delay="200" right :disabled="sm() || xs()">
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-caption md-1"><span v-on="on" v-bind="attrs">抖音<v-icon
                      color="white">mdi-music-note</v-icon></span></p>
                </template>
                <v-img src="douyin.png" dark max-width="300"/>
              </v-tooltip>
              <v-tooltip color="yellow" open-delay="200" right :disabled="sm() || xs()">
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-caption md-1"><span v-on="on" v-bind="attrs">快手<v-icon
                      color="white">mdi-video-account</v-icon></span></p>
                </template>
                <v-img src="kuaishou.png" dark max-width="200"/>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="xl() || lg() || md()" md="6" cols="12">
              <p class="text-caption md-1">Made with
                <v-icon color="red">mdi-heart</v-icon>
                in BJ. Copyright ©编程云 <span v-text="year">2023</span>.
              </p>
            </v-col>
            <v-col md="6" cols="12">
              <p class="text-caption md-1">All Rights Reserved. 津ICP备15008431号-1</p>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </v-footer>
  </v-app>
</template>
<style>
.v-card--link:focus {
  background: white;
}

.v-card--link:focus::before {
  opacity: 0;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    step: 0,
    wangyi : [
        "2-4.JPG",
        "2-1.JPG",
        "2-3.JPG"
    ],
    teams: [
      {
        name: "李彦博",
        title: "CEO",
        img: "colleague/Jay-CEO.pic.jpg"
      },
      {
        name: "梅俊宇",
        title: "CTO",
        img: "colleague/梅俊宇-CTO.pic.jpg"
      },
      {
        name: "李常友",
        title: "软件工程师",
        img: "colleague/李常友-软件工程师.pic.jpg"
      },
      {
        name: "梁海平",
        title: "金融数字化培训师",
        img: "colleague/梁老师-金融数字化培训师.pic.jpg"
      },
      {
        name: "高心怡",
        title: "教研总监",
        img: "colleague/Charlotte-教学产品研发.JPG"
      },
      {
        name: "贾俊华",
        title: "软件工程师",
        img: "colleague/贾俊华-软件工程师.jpeg"
      },
    ],
    year: new Date().getFullYear()
    //
  }),
  methods: {
    xl() {
      return this.$vuetify.breakpoint.xl
    },
    lg() {
      return this.$vuetify.breakpoint.lg
    },
    md() {
      return this.$vuetify.breakpoint.md
    },
    sm() {
      return this.$vuetify.breakpoint.sm
    },
    xs() {
      return this.$vuetify.breakpoint.xs
    },
  },
  computed: {
    left() {
      const {xl, lg, md, sm} = this.$vuetify.breakpoint
      return xl ? 1 : lg ? 1 : md ? 1 : sm ? 1 : 1;
    },
    right() {
      const {xl, lg, md, sm} = this.$vuetify.breakpoint
      return xl ? 1 : lg ? 1 : md ? 1 : sm ? 2 : 2;
    }
  }
};
</script>

